import { Options } from 'vue-class-component'
import { SuperVue } from '@/mixins/SuperVue'
import tabPane0 from './component/tab-pane0.vue'
import tabPane1 from './component/tab-pane1.vue'
import tabPane3 from './component/tab-pane3.vue'

@Options({
  components: { tabPane0, tabPane1, tabPane3 }
})

export default class TechInput extends SuperVue {
  created() {

  }

  /**
   * tab面板数据/操作
   */
  curTabPane= '3'
  tabPaneSort=[
    { label: '评价信息', comp: 'tabPane0' },
    { label: '基本信息', comp: 'tabPane1' },
    { label: '成果简介', comp: 'tabPane2' },
    { label: '结构分解WBS', comp: 'tabPane3' },
    { label: '技术水平', comp: 'tabPane4' },
    { label: '对标分析BMK' },
    { label: '应用推广情况' },
    { label: '经济效益' },
    { label: '社会效益' },
    { label: '知识产权情况' },
    { label: '项目团队' },
    { label: '各类风险' },
    { label: '投入产出情况' },
    { label: '科技查新情况' },
    { label: '检测试验情况' },
    { label: '技术估值' },
    { label: '融资需求' },
    { label: '附件' }
  ]
  showBtnGroup= false // 是否展示操作按钮
  // 面板下方操作栏点击
  tabsOperate (operate) {
    const temp = Number(this.curTabPane)
    const len = this.tabPaneSort.length

    const add = () => {
      this.modal1.open = true
    }
    const del = () => {
      // if (this.tabPaneSort[temp].presort <= 100) {
      //   this.$Message.error('原始列表不允许删除！')
      //   return
      // }
      this.tabPaneSort.splice(temp, 1)
      this.curTabPane = '0'
    }
    const up = () => {
      if (temp === 0) {
        // this.$Message.warning('非法操作！')
        return
      }
      // this.tabPaneSort.splice(temp - 1, 0, ...this.tabPaneSort.splice(temp, 1))
      this.curTabPane = (temp - 1).toString()
    }
    const drop = () => {
      if (temp === len - 1) {
        // this.$Message.warning('非法操作!')
        return
      }
      // this.tabPaneSort.splice(temp + 1, 0, ...this.tabPaneSort.splice(temp, 1))
      this.curTabPane = (temp + 1).toString()
    }
    const top = () => {
      this.tabPaneSort.splice(0, 0, ...this.tabPaneSort.splice(temp, 1))
      this.curTabPane = '0'
    }
    const down = () => {
      this.tabPaneSort.splice(len - 1, 0, ...this.tabPaneSort.splice(temp, 1))
      this.curTabPane = (len - 1).toString()
    }
    switch (operate) {
      case 'add':
        add()
        break
      case 'del':
        del()
        break
      case 'up':
        up()
        break
      case 'drop':
        drop()
        break
      case 'top':
        top()
        break
      case 'down':
        down()
        break
    }
  }

  /**
   * 新增tab面板弹窗
   */
  modal1 = {
    open: false,
    title: '添加列表项',
    name: ''
  }
  modal1Ok () {
    if (this.modal1.name.trim() === '') {
      // this.$Message.warning('请输入列表名！')
      return
    }
    const len = this.tabPaneSort.length
    // this.tabPaneSort.push({ label: this.modal1.name, presort: len + 100 })
    this.modal1.open = false
    this.modal1.name = ''
  }

  /**
   * 右下操作按钮事件
   */
  // 保存草稿/提交成功按钮点击
  submit () {
    // getJson('json/test.json').then(res =>
    //   console.log('getJson', JSON.parse(JSON.stringify(res)))
    // )
    // this.$refs['tabPane1'].testFormat()
    // console.log(JSON.stringify(this.$refs['tabPane1'].formBase))
  }
  // 在新标签页打开预览窗口
  toPreview () {
    const newPage = this.$router.resolve({
      name: 'preview'
      // query: { objectType: 1, infoId: 1}
    })
    window.open(newPage.href, '_blank')
  }

  /**
   * tabPane2操作/数据
   */
   pane2List= [
     {
       title: '项目来源和背景',
       value: '',
       popover: false
     },
     {
       title: '研究目的与意义',
       value: '',
       popover: false
     },
     {
       title: '成果所属技术领域、功能、特点（优点、创新点）',
       value: '',
       popover: false
     },
     {
       title: '研究内容（含技术路线与原理）',
       value: '',
       popover: false
     },
     {
       title: '已经取得的荣誉与获奖情况',
       value: '',
       popover: false
     }
   ]
  modalB= {
    open: false,
    title: '添加项目',
    name: ''
  }
  modalBOk () {
    if (this.modalB.name.trim() === '') {
      // this.$Message.warning('请输入列表名！')
      return;
    }
    this.pane2List.push({
      title: this.modalB.name.trim(),
      value: '',
      popover: false
    });
    this.modalB.open = false;
    this.modalB.name = '';
  }
  delPane2Item (index) {
    this.pane2List.splice(index, 1)
  }

  /**
   * tabPane4操作/数据
   */
   formTechnical = {
     item1: '',
     item1_config: {
       label: '成熟度',
       placeholder: '',
       radio: [
         { value: '0', label: 0 },
         { value: '1', label: 1 },
         { value: '2', label: 2 },
         { value: '3', label: 3 },
         { value: '4', label: 4 },
         { value: '5', label: 5 },
         { value: '6', label: 6 },
         { value: '7', label: 7 },
         { value: '8', label: 8 },
         { value: '9', label: 9 },
         { value: '10', label: 10 }
       ]
     },
     item2: '',
     item2_config: {
       label: '理由',
       placeholder: ''
     },
     item3: '',
     item3_config: {
       label: '创新度',
       placeholder: '',
       radio: [
         { value: '0', label: 0 },
         { value: '1', label: 1 },
         { value: '2', label: 2 },
         { value: '3', label: 3 },
         { value: '4', label: 4 },
         { value: '5', label: 5 },
         { value: '6', label: 6 },
         { value: '7', label: 7 },
         { value: '8', label: 8 },
         { value: '9', label: 9 },
         { value: '10', label: 10 }
       ]
     },
     item4: '',
     item4_config: {
       label: '理由',
       placeholder: ''
     },
     item5: '',
     item5_config: {
       label: '先进度',
       placeholder: '',
       radio: [
         { value: '0', label: 0 },
         { value: '1', label: 1 },
         { value: '2', label: 2 },
         { value: '3', label: 3 },
         { value: '4', label: 4 },
         { value: '5', label: 5 },
         { value: '6', label: 6 },
         { value: '7', label: 7 },
         { value: '8', label: 8 },
         { value: '9', label: 9 },
         { value: '10', label: 10 }
       ]
     },
     item6: '',
     item6_config: {
       label: '理由',
       placeholder: ''
     },
     item7: '',
     item7_config: {
       label: '其他技术指标描述',
       placeholder: '科学性、复杂性、可靠性、衍生性等'
     },
     item8: '',
     item8_config: {
       label: '问题与建议',
       placeholder: '请输入问题与建议'
     }
   };
   pane4level= [
     { title: '', content: '' },
     { title: '', content: '' },
     { title: '', content: '' }
   ]
  ruleTechnical= {}
  modalC= {
    open: false,
    title: '评分标准',
    score: '',
    reason: '',
    gist: [] as string[],
    indexList: [],
    placeholder: '请打分'
  }
  pane4norm= [
    ['无', '观测到原理并形成正式报告', '形成了技术概念或开发方案', '关键功能分析和实验结论成立', '研究室环境中的部件仿真验证', '相关环境中的部件仿真验证', '相关环境中的系统样机演示', '在实际环境中的系统样机试验结论成立', '实际系统完成并通过实际验证', '实际通过任务运行的成功考验，可销售', '已经有销售'],
    ['无明显创新', '只有少许非核心部分的创新', '小范围内创新', '非核心部分在国内该领域首创', '非核心部分是国内首创', '全国范围在该领域首创', '全国范围所有行业首创', '部分核心技术是世界首创', '整体是世界首创（但未应用新的科学原理）', '整体在世界范围该行业领域重大首创', '整体在世界范围内所有行业属重大首创（有应用新的科学原理）'],
    ['无明显先进性', '某小范围内稍微算先进', '某小范围内明显先进', '地市或细分领域先进', '地市或细分领域领先', '省或行业内先进', '省或行业内领先', '国内先进', '国内领先', '国际先进', '国际领先']
  ]

  pane4add () {
    if (this.pane4level.length >= 5) {
      this.$message.warning('最多添加五个技术指标！');
      return;
    }
    this.pane4level.push({
      title: '',
      content: ''
    });
  }
  pane4delSelf (index) {
    this.pane4level.splice(index, 1);
  }
  pane4showNorm (index) {
    this.modalC.open = true
    this.modalC.gist = this.pane4norm[index]
  }
}
