<template>
  <div>
    <div class="title">
      <div>成果基本信息</div>
    </div>
    <el-form ref="formResultBase" :model="formResultBase" size="mini" :rules="ruleResultBase" :label-width="110" label-position="right">
      <el-row>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item1_config.label" prop="item1">
            <el-input v-model="formResultBase.item1" :placeholder="formResultBase.item1_config.placeholder"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item2_config.label">
            <el-input v-model="formResultBase.item2" :placeholder="formResultBase.item2_config.placeholder"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item3_config.label" prop="item3">
            <el-select v-model="formResultBase.item3" multiple :placeholder="formResultBase.item3_config.placeholder">
              <el-option v-for="item in formResultBase.item3_config.option" :value="item.value" :key="item.value">{{ item.label }}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item4_config.label" prop="item4">
            <el-select v-model="formResultBase.item4" :placeholder="formResultBase.item4_config.placeholder">
              <el-option v-for="item in formResultBase.item4_config.option" :value="item.value" :key="item.value">{{ item.label }}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7" v-if="formResultBase.item4==1">
          <el-form-item  :label="formResultBase.item6_config.label" :prop="formResultBase.item4==1?'item6':''">
            <el-select v-model="formResultBase.item6" :placeholder="formResultBase.item6_config.placeholder">
              <el-option v-for="item in formResultBase.item6_config.option" :value="item.value" :key="item.value">{{ item.label }}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item5_config.label" prop="item5">
            <el-input v-model="formResultBase.item5" :placeholder="formResultBase.item5_config.placeholder"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item7_config.label" prop="item7">
            <el-input v-model="formResultBase.item7" :placeholder="formResultBase.item7_config.placeholder"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item9_config.label" prop="item9">
            <el-select v-model="formResultBase.item9" :placeholder="formResultBase.item9_config.placeholder">
              <el-option v-for="item in formResultBase.item9_config.option" :value="item.value" :key="item.value">{{ item.label }}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item10_config.label">
            <el-select v-model="formResultBase.item10" :placeholder="formResultBase.item10_config.placeholder">
              <el-option v-for="item in formResultBase.item10_config.option" :value="item.value" :key="item.value">{{ item.label }}</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item11_config.label">
            <el-input v-model="formResultBase.item11" :placeholder="formResultBase.item11_config.placeholder"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="formResultBase.item12_config.label">
            <el-input v-model="formResultBase.item12" :placeholder="formResultBase.item12_config.placeholder"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
        </el-col>
      </el-row>
      <el-form-item :label="formResultBase.item21_config.label" prop="item21">
        <el-radio-group v-model="formResultBase.item21">
          <el-radio :label="item.label" v-for="item in formResultBase.item21_config.checkbox" :key="item.label"></el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="title">
      <div>成果完成单位</div><button @click="addCompany">+添加完成单位</button>
    </div>
    <div v-for="item of 2" :key="item">
      <div style="font-size:14px;font-weight:600;margin: 5px;">第{{item}}完成单位</div>
      <comp-company :key="item"></comp-company>
    </div>

  </div>
</template>

<script>
import compCompany from './comp-company.vue'
export default {
  name: 'tabPane1',
  components: { compCompany },
  data() {
    return {
      formResultBase: formResultBase,
      ruleResultBase: ruleResultBase
    }
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$Message.success('Success!')
        } else {
          this.$Message.error('Fail!')
        }
        this.$emit('validate', { name: name, valid: valid })
      })
    },
    handleReset(name) {
      this.$refs[name].resetFields()
    },
    testFormat() {
      this.handleSubmit('formResultBase')
    },
    // 添加完成单位按钮点击
    addCompany() {}
  }
}

const temp = {
  cascader: [
    {
      value: 'beijing',
      label: '北京',
      children: [
        {
          value: 'gugong',
          label: '故宫'
        },
        {
          value: 'tiantan',
          label: '天坛'
        },
        {
          value: 'wangfujing',
          label: '王府井'
        }
      ]
    },
    {
      value: 'jiangsu',
      label: '江苏',
      children: [
        {
          value: 'nanjing',
          label: '南京',
          children: [
            {
              value: 'fuzimiao',
              label: '夫子庙'
            }
          ]
        },
        {
          value: 'suzhou',
          label: '苏州',
          children: [
            {
              value: 'zhuozhengyuan',
              label: '拙政园'
            },
            {
              value: 'shizilin',
              label: '狮子林'
            }
          ]
        }
      ]
    }
  ]
}

const formResultBase = {
  item1: '',
  item1_config: {
    label: '成果名称',
    placeholder: '请输入成果名称'
  },
  item2: '',
  item2_config: {
    value: '',
    label: '成果型号',
    placeholder: '请输入成果型号'
  },
  item3: [],
  item3_config: {
    label: '成果形式',
    placeholder: '请选择成果形式',
    option: [
      {
        value: '1',
        label: '新方法'
      },
      {
        value: '2',
        label: '新产品'
      },
      {
        value: '3',
        label: '新工艺'
      },
      {
        value: '4',
        label: '新材料/配方'
      },
      {
        value: '5',
        label: '新设备/工具'
      },
      {
        value: '6',
        label: '软件/系统'
      },
      {
        value: '7',
        label: '新品种'
      },
      {
        value: '8',
        label: '其他'
      }
    ]
  },
  item4: '',
  item4_config: {
    label: '成果来源',
    placeholder: '请选择成果来源',
    option: [
      {
        value: 1,
        label: '横向课题'
      },
      {
        value: 2,
        label: '纵向课题'
      },
      {
        value: 3,
        label: '其他'
      }
    ]
  },
  item5: '广东工业大学',
  item5_config: {
    label: '来源单位',
    placeholder: '请输入来源单位'
  },
  item6: '', // 仅在横向课题可用
  item6_config: {
    label: '来源单位性质',
    placeholder: '请选择来源单位性质',
    option: [
      {
        value: 1,
        label: '高校'
      },
      {
        value: 2,
        label: '科研机构'
      },
      {
        value: 3,
        label: '其他事业单位'
      },
      {
        value: 4,
        label: '社会组织'
      },
      {
        value: 5,
        label: '国企'
      },
      {
        value: 6,
        label: '名企'
      },
      {
        value: 7,
        label: '外企'
      },
      {
        value: 8,
        label: '混合所有制企业'
      },
      {
        value: 9,
        label: '其他'
      }
    ]
  },
  item7: '',
  item7_config: {
    label: '课题名称',
    placeholder: '请输入课题名称'
  },
  item8: '', // 仅在纵向课题可用
  item8_config: {
    label: '单位级别',
    placeholder: '请选择单位级别',
    option: [
      {
        value: 1,
        label: '国家级'
      },
      {
        value: 2,
        label: '省级'
      },
      {
        value: 3,
        label: '市级'
      },
      {
        value: 4,
        label: '区县级'
      },
      {
        value: 5,
        label: '其他'
      }
    ]
  },
  item9: '',
  item9_config: {
    value: '',
    label: '所属行业',
    placeholder: '下拉项计划做成动态的，后续开发', // '请选择所属行业',
    // todo
    option: [
      {
        value: '1',
        label: '下拉项计划做成动态的，后续开发'
      }
    ]
  },
  item10: '',
  item10_config: {
    value: '',
    label: '关联行业',
    placeholder: '请选择/填写相关行业',
    // todo
    option: [
      {
        value: '1',
        label: '服务业'
      }
    ],
    cascader: temp.cascader
  },
  item11: '',
  item11_config: {
    label: '联系人',
    placeholder: '请输入联系人'
  },
  item12: '',
  item12_config: {
    label: '联系方式',
    placeholder: '请输入联系方式'
  },
  item21: null,
  item21_config: {
    label: '成果类型',
    placeholder: '',
    checkbox: [
      { label: '应用技术', value: 0 },
      { label: '基础理论', value: 0 },
      { label: '软课题研究', value: 0 },
      { label: '教育教学', value: 0 },
      { label: '创新能力与条件建设', value: 0 },
      { label: '技术转移与推广', value: 0 },
      { label: '文学艺术实践', value: 0 },
      { label: '其它', value: 0 }
    ]
  }
}

const ruleResultBase = {
  item1: [{ required: true, message: ' ', trigger: 'blur' }],
  item2: [{ required: true, message: ' ', trigger: 'blur' }],
  item3: [{ required: true, message: ' ', trigger: 'blur' }],
  item4: [{ required: true, message: ' ', trigger: 'blur' }],
  item5: [{ required: true, message: ' ', trigger: 'blur' }],
  item6: [{ required: true, message: ' ', trigger: 'blur' }],
  // item7: [{ required: true, message: ' ', trigger: 'blur' }],
  item8: [{ required: true, message: ' ', trigger: 'blur' }],
  item9: [{ required: true, message: ' ', trigger: 'blur' }],
  item10: [{ required: true, message: ' ', trigger: 'blur' }],
  item11: [{ required: true, message: ' ', trigger: 'blur' }],
  item12: [{ required: true, message: ' ', trigger: 'blur' }],
  item21: [{ required: true, message: ' ', trigger: 'blur' }]
}
</script>

<style scoped lang="less">
.title {
  background-color: #f5f7f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 5px;
  & > :first-child {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 3px solid #366cec;
  }
  button {
    border: 0;
    padding-right: 10px;
    color: #666;
  }
  button:hover {
    color: #366cec;
  }
}
</style>
