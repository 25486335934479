<template>
  <div class="company-comp">
    <el-popover placement="left" v-model:visible="visible">
      <p>删除之后无法恢复，确定要删除吗?</p>
      <div class="flex-end">
        <el-button size="mini" type="text" @click="visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="delSelf">删除</el-button>
      </div>
      <template #reference>
        <i class="el-icon-delete del-button"></i>
      </template>
    </el-popover>

    <el-form ref="formCompany" :model="formCompany" :rules="ruleCompany" size="mini" :label-width="110" inline label-position="right">
      <el-form-item :label="formCompany.item1_config.label" prop="item1">
        <el-input v-model="formCompany.item1" :placeholder="formCompany.item1_config.placeholder" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item :label="formCompany.item2_config.label">
        <el-input v-model="formCompany.item2" :placeholder="formCompany.item2_config.placeholder" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item :label="formCompany.item3_config.label">
        <el-input type="textarea" v-model="formCompany.item3" :placeholder="formCompany.item3_config.placeholder" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item :label="formCompany.item4_config.label">
        <el-input v-model="formCompany.item4" :placeholder="formCompany.item4_config.placeholder" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item :label="formCompany.item5_config.label">
        <el-input v-model="formCompany.item5" :placeholder="formCompany.item5_config.placeholder" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item :label="formCompany.item6_config.label">
        <el-input v-model="formCompany.item6" :placeholder="formCompany.item6_config.placeholder" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item :label="formCompany.item7_config.label">
        <el-input v-model="formCompany.item7" :placeholder="formCompany.item7_config.placeholder" style="width:300px"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="less" scoped>
.company-comp {
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  position: relative;
  .del-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: tomato;
  }
}
</style>

<script>
export default {
  data () {
    return {
      formCompany: JSON.parse(JSON.stringify(formCompany)),
      ruleCompany: ruleCompany,
      visible: false
    }
  },
  methods: {
    // 删除自己
    delSelf () {}
  }
}

const formCompany = {
  item1: '',
  item1_config: {
    label: '单位名称',
    placeholder: '请输入单位名称'
  },
  item2: '',
  item2_config: {
    label: '组织机构代码',
    placeholder: '请输入组织机构代码'
  },
  item3: '',
  item3_config: {
    label: '单位地址',
    placeholder: '请输入单位地址'
  },
  item4: '',
  item4_config: {
    label: '主管部门',
    placeholder: '请输入主管部门'
  },
  item5: '',
  item5_config: {
    label: '单位性质',
    placeholder: '请选择单位性质'
  },
  item6: '',
  item6_config: {
    label: '项目负责人',
    placeholder: '请输入项目负责人'
  },
  item7: '',
  item7_config: {
    label: '联系电话',
    placeholder: '请输入联系电话'
  }
}

const ruleCompany = {
  item1: [{ required: true, message: '不能为空', trigger: 'blur' }]
}
</script>
