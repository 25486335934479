<template>
  <div class="tab-pane3">
    <div style="margin-bottom:10px;padding-bottom:10px;border-bottom:1px dashed #ccc;">
      <el-button size="mini" type="info" @click="showContainerBDrawer = !showContainerBDrawer">打开WBS结构</el-button>
      <strong style="margin-left:30px">当前WBS层级:</strong>
    </div>
    <!-- 抽屉 -->
    <el-drawer v-model="showContainerBDrawer" :width="300">
      <template #title>
        <b>WBS结构</b>
      </template>
      <el-button-group class="flex-center">
        <el-button size="small" type="warning" @click="editSelectWBS">编辑选中项</el-button>
        <el-button size="small" type="success" @click="addTree">添加子节点</el-button>
        <el-popover placement="bottom" v-model:visible="visible">
          <p>删除之后无法恢复，确定要删除吗?</p>
          <div class="flex-end">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @on-ok="delSelf">删除</el-button>
          </div>
          <template #reference>
            <el-button size="small" type="danger">删除本节点</el-button>
          </template>
        </el-popover>
      </el-button-group>
      <el-tree class="mt-20" :data="treeWBS" @on-select-change="treeSelectChange"></el-tree>
    </el-drawer>
    <!-- 表单 -->
    <div>
      <el-form ref="formWBS" :model="formWBS" :rules="ruleWBS" size="mini" :label-width="110" inline label-position="right">
        <el-form-item :label="formWBS.item1_config.label">
          <el-input v-model="formWBS.item1" :placeholder="formWBS.item1_config.placeholder" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item :label="formWBS.item2_config.label">
          <el-radio-group v-model="formWBS.item2" style="width:300px">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="formWBS.item3_config.label">
          <el-radio-group v-model="formWBS.item3" style="width:300px">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="formWBS.item30_config.label">
          <el-select :disabled="formWBS.item3==0" v-model="formWBS.item30" :placeholder="formWBS.item30_config.placeholder" style="width:300px">
            <el-option v-for="item in formWBS.item30_config.option" :value="item.value" :key="item.value">{{ item.label }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="formWBS.item4_config.label">
          <el-select v-model="formWBS.item4" :placeholder="formWBS.item4_config.placeholder" style="width:300px">
            <el-option v-for="item in formWBS.item4_config.option" :value="item.value" :key="item.value">{{ item.label }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="formWBS.item5_config.label">
          <el-select v-model="formWBS.item5" :placeholder="formWBS.item5_config.placeholder" style="width:450px" @on-change="astrictChange($event,'pre')">
            <el-option v-for="item in formWBS.item5_config.option" :value="item.value" :label="item.label" :key="item.value">
              <span>{{ item.label }}</span>
              <span style="float:right;color:#ccc">{{ item.msg }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="formWBS.item6_config.label">
          <el-select v-model="formWBS.item6" :placeholder="formWBS.item6_config.placeholder" style="width:450px" @on-change="astrictChange($event,'back')">
            <el-option v-for="item in formWBS.item6_config.option" :value="item.value" :label="item.label" :key="item.value">
              <span>{{ item.label }}</span>
              <span style="float:right;color:#ccc">{{ item.msg }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form ref="formWBS2" :model="formWBS2" :rules="ruleWBS2" size="mini" label-position="top">
        <el-form-item :label="formWBS2.item1_config.label">
          <el-input type="textarea" v-model="formWBS2.item1" :placeholder="formWBS2.item1_config.placeholder" style=""></el-input>
        </el-form-item>
        <el-form-item :label="formWBS2.item2_config.label">
          <el-input type="textarea" v-model="formWBS2.item2" :placeholder="formWBS2.item2_config.placeholder" style=""></el-input>
        </el-form-item>
        <el-form-item :label="formWBS2.item3_config.label">
          <el-input type="textarea" v-model="formWBS2.item3" :placeholder="formWBS2.item3_config.placeholder" style=""></el-input>
        </el-form-item>
        <el-form-item :label="formWBS2.item4_config.label">
          <el-input type="textarea" v-model="formWBS2.item4" :placeholder="formWBS2.item4_config.placeholder" style=""></el-input>
        </el-form-item>
        <el-form-item :label="formWBS2.item5_config.label">
          <el-input type="textarea" v-model="formWBS2.item5" :placeholder="formWBS2.item5_config.placeholder" style=""></el-input>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'tabPane3',
  components: {
  },
  data () {
    return {
      visible: false,
      showContainerBDrawer: false,
      curSelectTreeWBS: 1, // 当前选中树项的标识
      treeWBS: [
        {
          label: '节点 1',
          children: [
            {
              label: '节点 1-1',
              children: [
                {
                  label: '节点 1-1-1'
                },
                {
                  label: '节点 1-1-2'
                }
              ]
            },
            {
              label: '节点 1-2',
              children: [
                {
                  label: '节点 1-2-1'
                },
                {
                  label: '节点 1-2-1'
                }
              ]
            }
          ]
        }
      ],
      formWBS: formWBS,
      formWBS2: formWBS2,
      ruleWBS: {},
      ruleWBS2: {}
    }
  },
  methods: {
    // 甲方需求，研发前低于研发后
    astrictChange (value, type) {
      if (this.formWBS.item5 === '' || this.formWBS.item6 === '') return
      if (this.formWBS.item5 > this.formWBS.item6) {
        if (type === 'pre') this.formWBS.item5 = null
        if (type === 'back') this.formWBS.item6 = null
        this.$Message.warning('研发前成熟度需小于研发后成熟度！');
      }
    },
    treeSelectChange (e) {
      console.log('treeSelectChange', e)
    },
    editSelectWBS () { },
    addTree () { },
    delTree () {
      // 如果是根节点，不允许删除
      console.log('delTree')
      this.visible = false
    },
    treeInit () {

    }
  }
}
const formWBS = {
  item1: '',
  item1_config: {
    label: '三级模块名称',
    placeholder: '请输入三级模块名称'
  },
  item2: 1,
  item2_config: {
    label: '完成方式',
    placeholder: ''
  },
  item3: 1,
  item3_config: {
    label: '是否核心',
    placeholder: ''
  },
  item30: null,
  item30_config: {
    label: '展示顺序',
    placeholder: '请选择展示顺序',
    option: [
      {
        value: 1,
        label: '1'
      },
      {
        value: 2,
        label: '2'
      },
      {
        value: 3,
        label: '3'
      },
      {
        value: 4,
        label: '4'
      },
      {
        value: 5,
        label: '5'
      }
    ]
  },
  item4: '',
  item4_config: {
    label: '成果形式',
    placeholder: '请选择成果形式',
    option: [
      {
        value: '1',
        label: '服务业'
      }
    ]
  },
  item5: '',
  item5_config: {
    label: '研发前成熟度',
    placeholder: '研发前成熟度',
    option: [
      {
        value: 10,
        label: '10销售级/应用级',
        msg: '已经有销售'
      },
      {
        value: 9,
        label: '9系统级',
        msg: '实际通过任务运行的成功考验，可销售'
      },
      {
        value: 8,
        label: '8产品级',
        msg: '实际系统完成并通过实际验证'
      },
      {
        value: 7,
        label: '7环境级',
        msg: '在实际环境中的系统样机试验结论成立'
      },
      {
        value: 6,
        label: '6正样级',
        msg: '相关环境中的系统样机演示'
      },
      {
        value: 5,
        label: '5初样级',
        msg: '相关环境中的部件仿真验证'
      },
      {
        value: 4,
        label: '4仿真级',
        msg: '研究室环境中的部件仿真验证'
      },
      {
        value: 3,
        label: '3功能级',
        msg: '关键功能分析和实验结论成立'
      },
      {
        value: 2,
        label: '2方案级',
        msg: '形成了技术概念或开发方案'
      },
      {
        value: 1,
        label: '1报告级',
        msg: '观测到原理并形成正式报告'
      },
      {
        value: 0,
        label: '0无',
        msg: ''
      }
    ]
  },
  item6: '',
  item6_config: {
    label: '研发后成熟度',
    placeholder: '研发后成熟度',
    option: [
      {
        value: 10,
        label: '10销售级/应用级',
        msg: '已经有销售'
      },
      {
        value: 9,
        label: '9系统级',
        msg: '实际通过任务运行的成功考验，可销售'
      },
      {
        value: 8,
        label: '8产品级',
        msg: '实际系统完成并通过实际验证'
      },
      {
        value: 7,
        label: '7环境级',
        msg: '在实际环境中的系统样机试验结论成立'
      },
      {
        value: 6,
        label: '6正样级',
        msg: '相关环境中的系统样机演示'
      },
      {
        value: 5,
        label: '5初样级',
        msg: '相关环境中的部件仿真验证'
      },
      {
        value: 4,
        label: '4仿真级',
        msg: '研究室环境中的部件仿真验证'
      },
      {
        value: 3,
        label: '3功能级',
        msg: '关键功能分析和实验结论成立'
      },
      {
        value: 2,
        label: '2方案级',
        msg: '形成了技术概念或开发方案'
      },
      {
        value: 1,
        label: '1报告级',
        msg: '观测到原理并形成正式报告'
      },
      {
        value: 0,
        label: '0无',
        msg: ''
      }
    ]
  }
}
const formWBS2 = {
  item1: '',
  item1_config: {
    label: '创新点与技术风险（可实现风险、可靠性风险、知识产权风险等）',
    placeholder: ''
  },
  item2: '',
  item2_config: {
    label: '市场风险（技术更迭与替代风险、同业竞争风险、供应商与客户风险等）',
    placeholder: ''
  },
  item3: '',
  item3_config: {
    label: '质量Quality（功能、性能、可靠性等指标与状态）',
    placeholder: ''
  },
  item4: '',
  item4_config: {
    label: '成本Cost（人力、物力、财力投入）',
    placeholder: ''
  },
  item5: '',
  item5_config: {
    label: '交付Delivery（效率、完成所需时间、时间节点等）',
    placeholder: ''
  }
}
</script>

<style lang="less" scoped>
.tab-pane3 {
overflow: hidden;
  position: relative;
  min-height: 100%;

}
</style>

