<template>
  <div>
    <div class="title">
      <div>评价信息</div>
    </div>
    <el-form ref="formAppraise" :model="formAppraise" :rules="ruleAppraise" size="mini" :label-width="130" label-position="right">
      <el-form-item :label="formAppraise.item1_config.label" prop="item1">
        <el-radio-group v-model="formAppraise.item1">
          <el-radio :label="item.label" v-for="item in formAppraise.item1_config.radio" :key="item.label">{{item.value}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="formAppraise.item1">
        <el-form-item :label="formAppraise.item10_config.label" prop="item10">
          <el-radio :value="true">自评</el-radio>
          <el-radio-group v-model="formAppraise.item10" class="inline-flex">
            <el-radio :label="item.label" v-for="item in formAppraise.item10_config.radio" :key="item.label" @click="radioClick(item.label)">{{item.value}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="formAppraise.item2_config.label" prop="item2">
          <div v-for="(item0,index0) in formAppraise.item2" :key="index0" style="display:flex">
            <el-checkbox :indeterminate="item0.indeterminate" :value="item0.checkAll" @click.prevent="handleCheckAll(index0)" style="width: 115px;">{{item0.value}}</el-checkbox>
            <el-checkbox-group v-model="item0.checkAllGroup" @on-change="checkAllGroupChange($event,index0)">
              <el-checkbox :label="item1" v-for="(item1,index1) in item0.groupValue" :key="index1" style="width: 120px;"></el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>

        <el-row>
          <el-col :span="11">
            <el-form-item :label="formAppraise.item3_config.label" prop="item3">
              <el-date-picker type="date" :placeholder="formAppraise.item3_config.placeholder" v-model="formAppraise.item3"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="1">
          </el-col>
          <el-col :span="11">
            <el-form-item :label="formAppraise.item4_config.label" prop="item4">
              <el-input v-model="formAppraise.item4" :placeholder="formAppraise.item4_config.placeholder"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item :label="formAppraise.item5_config.label" prop="item5">
              <el-input v-model="formAppraise.item5" :placeholder="formAppraise.item5_config.placeholder"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
          </el-col>
          <el-col :span="11">
            <el-form-item :label="formAppraise.item6_config.label" prop="item6">
              <el-input v-model="formAppraise.item6" :placeholder="formAppraise.item6_config.placeholder"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item :label="formAppraise.item7_config.label" prop="item7">
              <el-input v-model="formAppraise.item7" :placeholder="formAppraise.item7_config.placeholder"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
          </el-col>
          <el-col :span="11">
            <el-form-item :label="formAppraise.item8_config.label" prop="item8">
              <el-input v-model="formAppraise.item8" :placeholder="formAppraise.item8_config.placeholder"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23">
            <el-form-item :label="formAppraise.item9_config.label" prop="item9">
              <el-input type="textarea" v-model="formAppraise.item9" :placeholder="formAppraise.item9_config.placeholder"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="dashed" v-if="formAppraise.item1==1">
      <el-form ref="formEvaluate" :model="formEvaluate" :rules="ruleEvaluate" size="mini" :label-width="80" label-position="right">
        <div style="color:#3bb8f1;font-size:20px">{{formEvaluate.item1_config.label}}</div>
        <el-form-item label="创新度" prop="item1">
          <el-input v-model="formEvaluate.item1" type="textarea" :placeholder="formEvaluate.item1_config.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="formEvaluate.item2_config.label" prop="item2">
          <el-radio-group v-model="formEvaluate.item2" size="small" class="inline-flex">
            <el-radio :label="item" v-for="item of 10" :key="item">{{item}}</el-radio>
          </el-radio-group>
          <el-button type="info" size="mini" @click="openModal1" class="ml-20">评分标准</el-button>
        </el-form-item>
        <div style="color:#3bb8f1;font-size:20px">{{formEvaluate.item3_config.label}}</div>
        <el-form-item :label="formEvaluate.item3_config.label" prop="item3">
          <el-input v-model="formEvaluate.item3" type="textarea" :placeholder="formEvaluate.item3_config.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="formEvaluate.item4_config.label" prop="item4">
          <el-radio-group v-model="formEvaluate.item4" size="small" class="inline-flex">
            <el-radio :label="item" v-for="item of 10" :key="item">{{item}}</el-radio>
          </el-radio-group>
          <el-button type="info" size="mini" @click="openModal1" class="ml-20">评分标准</el-button>
        </el-form-item>
        <div style="color:#3bb8f1;font-size:20px">{{formEvaluate.item5_config.label}}</div>
        <el-form-item :label="formEvaluate.item5_config.label" prop="item5">
          <el-input v-model="formEvaluate.item5" type="textarea" :placeholder="formEvaluate.item5_config.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="formEvaluate.item6_config.label" prop="item6">
          <el-radio-group v-model="formEvaluate.item6" size="small" class="inline-flex">
            <el-radio :label="item" v-for="item of 10" :key="item">{{item}}</el-radio>
          </el-radio-group>
          <el-button type="info" size="mini" @click="openModal1" class="ml-20">评分标准</el-button>
        </el-form-item>
        <div style="color:#3bb8f1;font-size:20px">{{formEvaluate.item7_config.label}}</div>
        <el-form-item :label="formEvaluate.item7_config.label" prop="item7">
          <el-input v-model="formEvaluate.item7" type="textarea" :placeholder="formEvaluate.item7_config.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="formEvaluate.item8_config.label" prop="item8">
          <el-radio-group v-model="formEvaluate.item8" size="small" class="inline-flex">
            <el-radio :label="item" v-for="item of 10" :key="item">{{item}}</el-radio>
          </el-radio-group>
          <el-button type="info" size="mini" @click="openModal1" class="ml-20">评分标准</el-button>
        </el-form-item>
        <div style="color:#3bb8f1;font-size:20px">{{formEvaluate.item9_config.label}}</div>
        <el-form-item :label="formEvaluate.item9_config.label" prop="item9">
          <el-input v-model="formEvaluate.item9" type="textarea" :placeholder="formEvaluate.item9_config.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="formEvaluate.item10_config.label" prop="item10">
          <el-radio-group v-model="formEvaluate.item10" size="small" class="inline-flex">
            <el-radio :label="item" v-for="item of 10" :key="item">{{item}}</el-radio>
          </el-radio-group>
          <el-button type="info" size="mini" @click="openModal1" class="ml-20">评分标准</el-button>
        </el-form-item>
        <div style="color:#3bb8f1;font-size:20px">{{formEvaluate.item11_config.label}}</div>
        <el-form-item :label="formEvaluate.item11_config.label" prop="item11">
          <el-input v-model="formEvaluate.item11" type="textarea" :placeholder="formEvaluate.item11_config.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="formEvaluate.item12_config.label" prop="item12">
          <el-radio-group v-model="formEvaluate.item12" size="small" class="inline-flex">
            <el-radio :label="item" v-for="item of 10" :key="item">{{item}}</el-radio>
          </el-radio-group>
          <el-button type="info" size="mini" @click="openModal1" class="ml-20">评分标准</el-button>
        </el-form-item>
        <div style="color:#3bb8f1;font-size:20px">{{formEvaluate.item13_config.label}}</div>
        <el-form-item :label="formEvaluate.item13_config.label" prop="item13">
          <el-input v-model="formEvaluate.item13" type="textarea" :placeholder="formEvaluate.item13_config.placeholder"></el-input>
        </el-form-item>
        <el-form-item :label="formEvaluate.item14_config.label" prop="item14">
          <el-radio-group v-model="formEvaluate.item14" size="small" class="inline-flex">
            <el-radio :label="item" v-for="item of 10" :key="item">{{item}}</el-radio>
          </el-radio-group>
          <el-button type="info" size="mini" @click="openModal1" class="ml-20">评分标准</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog v-model="modal1.open" :title="modal1.title" :width="50">
      <div style="line-height:35px">
        <div style="font-size:16px">评价标准：{{modal1.evaluation_criterion}}</div>
        <div style="margin-left:20px" v-for="item in modal1.weight_items" :key="item.id">({{item.score}}).{{item.title}}；</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'tabPane0',
  data() {
    return {
      formAppraise: formAppraise,
      ruleAppraise: ruleAppraise,
      formEvaluate: formEvaluate,
      ruleEvaluate: ruleEvaluate,
      modal1: {
        open: false,
        title: '评分标准',
        evaluation_criterion: '评分标准评分标准评分标准评分标准评分标准',
        weight_items: [
          { score: '1', title: '分数说明' },
          { score: '2', title: '分数说明' },
          { score: '3', title: '分数说明' },
          { score: '4', title: '分数说明' },
          { score: '5', title: '分数说明' },
          { score: '6', title: '分数说明' },
          { score: '7', title: '分数说明' },
          { score: '8', title: '分数说明' },
          { score: '9', title: '分数说明' },
          { score: '10', title: '分数说明' }
        ]
      }
    }
  },
  methods: {
    openModal1() {
      this.modal1.open = true
    },
    // 全选按钮点击
    handleCheckAll(index0) {
      if (this.formAppraise.item2[index0].indeterminate) {
        this.formAppraise.item2[index0].checkAll = false
      } else {
        this.formAppraise.item2[index0].checkAll
          = !this.formAppraise.item2[index0].checkAll
      }
      this.formAppraise.item2[index0].indeterminate = false

      if (this.formAppraise.item2[index0].checkAll) {
        this.formAppraise.item2[index0].checkAllGroup
          = this.formAppraise.item2[index0].groupValue
      } else {
        this.formAppraise.item2[index0].checkAllGroup = []
      }
    },
    // 多选按钮某一项发生切换
    checkAllGroupChange(data, index0) {
      if (data.length === this.formAppraise.item2[index0].groupValue.length) {
        this.formAppraise.item2[index0].indeterminate = false
        this.formAppraise.item2[index0].checkAll = true
      } else if (data.length > 0) {
        this.formAppraise.item2[index0].indeterminate = true
        this.formAppraise.item2[index0].checkAll = false
      } else {
        this.formAppraise.item2[index0].indeterminate = false
        this.formAppraise.item2[index0].checkAll = false
      }
    },
    radioClick(value) {
      if (this.formAppraise.item10 === value) {
        setTimeout(() => {
          this.formAppraise.item10 = 100
          this.$forceUpdate()
        }, 200)
      }
    }
  }
}

const formAppraise = {
  item1: 1,
  item1_config: {
    label: '是否需要评价',
    placeholder: '',
    radio: [
      {
        value: '否',
        label: 0
      },
      {
        value: '是',
        label: 1
      }
    ]
  },
  item2: [
    {
      checkAll: false,
      value: '技术交易',
      indeterminate: false,
      checkAllGroup: [],
      groupValue: ['转让', '许可', '作价投资', '质押融资']
    },
    {
      checkAll: false,
      value: '应用推广',
      indeterminate: false,
      checkAllGroup: [],
      groupValue: ['市场推广', '项目引进']
    },
    {
      checkAll: false,
      value: '水平鉴定',
      indeterminate: false,
      checkAllGroup: [],
      groupValue: ['报奖', '人才评价', '成果管理', '创新规划']
    },
    {
      checkAll: false,
      value: '项目管理',
      indeterminate: false,
      checkAllGroup: [],
      groupValue: ['项目立项', '项目监督', '项目结题']
    }
  ],
  item2_config: {
    label: '评价目的',
    placeholder: ''
  },

  item3: '',
  item3_config: {
    label: '评价完成时间',
    placeholder: '请选择评价完成时间'
  },
  item4: '',
  item4_config: {
    label: '评价费用（元）',
    placeholder: '请输入评价费用（元）'
  },
  item5: '',
  item5_config: {
    label: '委托评价联系人',
    placeholder: '请输入委托评价联系人'
  },
  item6: '',
  item6_config: {
    label: '联系电话',
    placeholder: '请输入联系电话'
  },
  item7: '',
  item7_config: {
    label: '微信',
    placeholder: '请输入微信号'
  },
  item8: '',
  item8_config: {
    label: '邮箱',
    placeholder: '请输入邮箱'
  },
  item9: '',
  item9_config: {
    label: '评价备注要求',
    placeholder: '请输入评价备注要求'
  },
  item10: 1,
  item10_config: {
    label: '评估类型',
    placeholder: '',
    radio: [
      // {
      //   value: '自评',
      //   label: 0
      // },
      {
        value: '第二方评',
        label: 1
      },
      {
        value: '第三方评',
        label: 4
      },
      {
        value: '职能部门评',
        label: 2
      },
      {
        value: '自邀专家评',
        label: 3
      }
    ]
  }
}

const ruleAppraise = {
  item1: [{ required: true, message: ' ', trigger: 'blur' }],
  item2: [{ required: true, message: ' ', trigger: 'blur' }],
  // item21: [{ required: true, message: ' ', trigger: 'blur' }],
  item3: [{ required: true, message: ' ', trigger: 'blur' }],
  item4: [{ required: true, message: ' ', trigger: 'blur' }],
  item5: [{ required: true, message: ' ', trigger: 'blur' }],
  item6: [{ required: true, message: ' ', trigger: 'blur' }],
  // item7: [{ required: true, message: " ", trigger: "blur" }],
  // item8: [{ required: true, message: " ", trigger: "blur" }],
  item10: [{ required: true, message: ' ', trigger: 'blur' }]
}

const formEvaluate = {
  item1: '',
  item1_config: {
    label: '创新度',
    placeholder: ''
  },
  item2: '',
  item2_config: {
    label: '评分',
    placeholder: ''
  },
  item3: '',
  item3_config: {
    label: '先进度',
    placeholder: ''
  },
  item4: '',
  item4_config: {
    label: '评分',
    placeholder: ''
  },
  item5: '',
  item5_config: {
    label: '成熟度',
    placeholder: ''
  },
  item6: '',
  item6_config: {
    label: '评分',
    placeholder: ''
  },
  item7: '',
  item7_config: {
    label: '稳定度',
    placeholder: ''
  },
  item8: '',
  item8_config: {
    label: '评分',
    placeholder: ''
  },
  item9: '',
  item9_config: {
    label: '科学性',
    placeholder: ''
  },
  item10: '',
  item10_config: {
    label: '评分',
    placeholder: ''
  },
  item11: '',
  item11_config: {
    label: '复杂性',
    placeholder: ''
  },
  item12: '',
  item12_config: {
    label: '评分',
    placeholder: ''
  },
  item13: '',
  item13_config: {
    label: '延伸性',
    placeholder: ''
  },
  item14: '',
  item14_config: {
    label: '评分',
    placeholder: ''
  }
}

const ruleEvaluate = {
  item1: [{ required: true, message: ' ', trigger: 'blur' }],
  item2: [{ required: true, message: ' ', trigger: 'blur' }],
  item3: [{ required: true, message: ' ', trigger: 'blur' }],
  item4: [{ required: true, message: ' ', trigger: 'blur' }],
  item5: [{ required: true, message: ' ', trigger: 'blur' }],
  item6: [{ required: true, message: ' ', trigger: 'blur' }],
  item7: [{ required: true, message: ' ', trigger: 'blur' }],
  item8: [{ required: true, message: ' ', trigger: 'blur' }],
  item9: [{ required: true, message: ' ', trigger: 'blur' }],
  item10: [{ required: true, message: ' ', trigger: 'blur' }],
  item11: [{ required: true, message: ' ', trigger: 'blur' }],
  item12: [{ required: true, message: ' ', trigger: 'blur' }],
  item13: [{ required: true, message: ' ', trigger: 'blur' }],
  item14: [{ required: true, message: ' ', trigger: 'blur' }]
}
</script>

<style lang="less" scoped>
.dashed {
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 10px;
  position: relative;
}
.title {
  background-color: #f5f7f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin: 5px;
  & > :first-child {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 3px solid #366cec;
  }
  button {
    border: 0;
    padding-right: 10px;
    color: #666;
  }
  button:hover {
    color: #366cec;
  }
}
</style>
